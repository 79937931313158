<template>
  <KTCard :page="page">
    <template #toolbar>
      <KTAddButton :to="addRoute" v-if="!finalizado" />
    </template>
    <template #body>
      <KTTable
        :fields="mao_de_obra_columns"
        :items="mao_de_obra.data"
        :primaryKey="mao_de_obra.primaryKey"
        :sortBy="mao_de_obra.sortBy"
      >
        <template #cell(_actions)="{ item }">
          <KTViewMiniButton
            :to="getEditRoute(item.idpip_mao_de_obra)"
            v-if="finalizado"
          />
          <KTEditMiniButton
            class="mr-3"
            :to="getEditRoute(item.idpip_mao_de_obra)"
            v-else
          />
          <KTRemoveMiniButton
            @click="ApiRemoveItem(item.idpip_mao_de_obra)"
            v-if="!finalizado"
          />
        </template>
      </KTTable>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipMaoDeObraList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-handshake",
        title: "Mão de Obra",
        description: `Clique no botão Adicionar para cadastrar a mão de obra
          envolvida em todos os setores da empresa. Repita esse processo até
          completar o quadro de funcionários. Para remover ou editar algum item
          use os respectivos botões localizados abaixo de AÇÕES.`
      },
      mao_de_obra: {
        data: [],
        primaryKey: "idpip_mao_de_obra",
        sortBy: "descricao"
      },
      anos: 0,
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/mao_de_obra`;
    },
    manual() {
      return {
        session: "mao_de_obra",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    addRoute() {
      return {
        name: "pip_mao_de_obra_new",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    mao_de_obra_columns() {
      let columns = [
        {
          key: "_actions",
          label: "Ações",
          sortable: false,
          tdClass: "text-center"
        },
        {
          key: "descricao",
          label: "Descrição",
          sortable: true
        },
        {
          key: "descricao_tipo",
          label: "Tipo",
          sortable: true
        }
      ];

      for (let ano = 1; ano <= this.anos; ano++) {
        columns.push({
          key: "quantidade_funcionarios_ano_" + ano,
          label: "Quantidade Funcionários Ano " + ano,
          sortable: true,
          tdClass: "text-right",
          formatter: "number"
        });
        columns.push({
          key: "salario_mensal_ano_" + ano,
          label: "Salário Mensal Ano " + ano,
          sortable: true,
          tdClass: "text-right",
          formatter: "currency"
        });
      }

      return columns;
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.mao_de_obra.data = res[R_GETLIST].mao_de_obra;
        this.anos = parseInt(res[R_GETLIST].anos);
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;
        this.finalizado = res[R_GETLIST].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    getEditRoute(id) {
      return {
        name: "pip_mao_de_obra_edit",
        params: {
          idplano_investimento: this.idplano_investimento,
          idpip_mao_de_obra: id
        }
      };
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
